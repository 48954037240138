import axios from 'axios';
import * as React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import * as ReactRouterPropTypes from 'react-router-prop-types';
import _ from 'lodash';
import {CardActionType, MultiGrid} from 'components/MultiGrid';
import PageMetaData from 'components/PageMetaData';
import * as UserStore from 'stores/User';
import * as DashboardStore from 'stores/Dashboard';

@withRouter
@connect(
    (state) => ({user: state.user, dashboard: state.dashboard}),
    _.merge({}, UserStore.actionCreators, DashboardStore.actionCreators)
)
export default class AwaitingPo extends React.Component {
    static propTypes = {
        ...ReactRouterPropTypes,
        ...UserStore.ActionShape,
        ...DashboardStore.ActionShape,
        user: UserStore.StateShape,
        dashboard: DashboardStore.StateShape,
    };
    
    constructor(props) {
        super(props);

        this.state = {
            gridName: 'awaitingPoColumns',
            columns: [],
            view: 'Grid',
            orders: [],
            loading: true,
        }
    }

    componentDidMount() {
        const {gridName} = this.state;
        axios.get(`/ShoppingService/api/v1/account/gridSetting/${gridName}`).then(x => {
            this.setState({columns: x.data.columns, view: x.data.view});
        });

        this.onRefresh();
    }

    getData() {
        const {user: {settings: {showAwaitingPoMenu}}, dashboard: {myAccountFilter}, resetMyAccountFilter} = this.props;

        if (!showAwaitingPoMenu) {
            this.setState({loading: false});
            return;
        }

        let request = {
            awaitingPo: true,
            showAllOrders: true,
            lineItemIds: myAccountFilter.lineItemIds,
        };

        this.setState({loading: true});
        axios.post('/ShoppingService/api/v1/orders/myOrders', request).then(x => {
            let data = x.data;
            data.forEach(x => {
                x.price = x.price.toFixed(2);
                x.extendedPrice = x.extendedPrice.toFixed(2);
                x.shipping = x.shipping.toFixed(2);
                x.fees = x.fees.toFixed(2);
                x.subTotal = x.subTotal.toFixed(2);
            });
            if (myAccountFilter.lineItemIds) resetMyAccountFilter();
            this.setState({orders: data, loading: false});
        });
    }

    handleSelect(item) {
        const {history} = this.props;
        history.push(`/order/${item.lineItemId}`);
    }

    onRefresh() {
        this.getData();
    }

    render() {
        const {gridName, columns, view, orders, loading} = this.state;

        return (<div style={{paddingBottom: '300px'}}>
            <PageMetaData 
                title="Awaiting P.O." 
                pageType="other"
                trackAnalytics={true} />
            <MultiGrid
                gridName={gridName}
                view={view}
                cardAction={CardActionType.AwaitingPO}
                data={orders}
                columns={columns}
                label="My Account"
                subLabel="Awaiting P.O."
                onSelect={::this.handleSelect}
                selectionMode="multiple"
                onRefresh={::this.onRefresh}
                loadingData={loading}
                height="600"
            />
        </div>)
    }
}
